<template>
    <ListFrame
        :no-results="resultListEmpty"
        :loading="initialMechanicsLoading"
        :error="error"
        :small-content-container="false"
        :large-content-container="true"
        :no-results-error-message="noResultsErrorMessage"
    >
        <template #filter="{ isMobile }">
            <ListFilterUrlSync
                v-model:selected="selected"
                sort-save-key="mechanic_sort"
                :fetch-aggregation="fetchAggregation"
                :facet-collection-id="FacetCollection.parts"
                :is-mobile-view="isMobile"
                :more-menu-entries="configMenuEntries"
                :sort-order-entries="sortOrderItems"
                :product="productID"
                @on-sort-selection-changed="onChangeSortOrder"
                @on-open-filter="onOpenFilterHandler"
            />
        </template>
        <template #count>
            {{ searchHitsText }}
        </template>
        <template #content>
            <Table
                data-qs="mechanic-articles-list-table"
                :q-data-source="qDataSource"
                :no-data-text="noResultsErrorMessage"
                :has-more-results="hasMoreResults"
                :custom-sticky-right-cell-column-name="ADD_TO_COLLECTION_COL_NAME"
                :custom-cells="[QIT_PREVIEW_IMAGE_COLUMN]"
                :mobile-action-column-name="ADD_TO_COLLECTION_COL_NAME"
                sticky-last-column
                @on-load-more="loadMoreArticles()"
                @row-click="onRowClick"
            >
                <template #cell-content="{ row, cell }">
                    <router-link :to="routeToMechanicArticleRedirect(row.id)" class="flex">
                        <q-item-label>{{ cell }}</q-item-label>
                    </router-link>
                </template>
                <template #custom-cell-column-name="{ row }">
                    <ShowIfHasAccess
                        :resource="AccessResource.collections"
                        :action="AccessAction.write"
                        :ignore-conditions="true"
                    >
                        <QitBtn
                            color="secondary"
                            size="sm"
                            padding="sm"
                            icon="fak fa-regular fa-fa-regular-bookmark-circle-plus default-grey-icon-color"
                            class="add-article-to-collection-button"
                            :data-qs="`add-article-to-collection-button-${row.id}`"
                            @click="openCollectionOverlay(row)"
                            @click.stop
                        >
                            <q-tooltip>{{ $t("collections.Add to Collection") }}</q-tooltip>
                        </QitBtn>
                    </ShowIfHasAccess>
                </template>
                <template #mobile-action="{ row }">
                    <div class="row flex-grow flex-end q-mr-md">
                        <QitBtn
                            color="secondary"
                            size="sm"
                            padding="sm"
                            icon="fak fa-regular fa-fa-regular-bookmark-circle-plus default-grey-icon-color"
                            class="add-article-to-collection-button"
                            :data-qs="`add-article-to-collection-button-${row.id}`"
                            @click="openCollectionOverlay(row)"
                            @click.stop
                        >
                            <q-tooltip>{{ $t("collections.Add to Collection") }}</q-tooltip>
                        </QitBtn>
                    </div>
                </template>
            </Table>
        </template>
    </ListFrame>
    <CollectionArticleOverlay
        v-if="articleModelOverlay"
        :article="articleModelOverlay"
        :product-id="product"
        :asset-id="asset"
        @on-close="closeCollectionsOverlay"
        @on-save-succeeded="closeCollectionsOverlay"
    />
</template>

<script setup lang="ts">
/* Komponente, die eine Liste von einbauortspezifischen Artikeln anzeigt. */
import { computed, ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useMechanicArticleListConfig, useMechanicArticlesListSort } from "./mechanic-articles-list.model";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { Facet, FacetCollection } from "@/shared/facets/facets.model";
import { useSelectedFacets } from "@/shared/facets/composables/selected-facets";
import ListFilterUrlSync from "@/shared/list-filter/list-filter-renderer-url-sync.vue";
import ListFrame from "@/shared/list-frame/list-frame.vue";
import Table from "@/shared/components/table/table.vue";
import { generateSearchHitsText } from "@/shared/utils/total-formatter";
import { useRouter } from "vue-router";
import { useMechanicArticlesDataSource } from "@/abilities/mechanic/pages/article-search/mechanic-articles-data-source";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import CollectionArticleOverlay, {
    ArticleModelOverlay,
} from "@/abilities/collections/components/overlay/collection-article-overlay.vue";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { ADD_TO_COLLECTION_COL_NAME } from "@/abilities/mechanic/pages/article-search/articles-list-data-source.ts";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control.ts";
import ShowIfHasAccess from "@/shared/access-control/components/show-if-has-access.vue";
import { QIT_PREVIEW_IMAGE_COLUMN, QTableRowType } from "@/shared/components/table/q-data-source";

const props = defineProps<{
    fullTextSearchValue: string;
    product: string;
    asset?: string;
}>();

const articleModelOverlay = ref<ArticleModelOverlay | undefined>();
const facet = ref<Facet | undefined>();

const { product, asset } = toRefs(props);

const router = useRouter();
const { getPathToChildrenWithoutParents } = useAbilityRoute();

const productID = computed(() => {
    if (!asset?.value && product?.value) return product?.value;

    return undefined;
});

const fullTextSearchValue = computed(() => {
    return props.fullTextSearchValue;
});

const { sorting, onChangeSortOrder, selected } = useSelectedFacets();

function onRowClick(row: QTableRowType) {
    router.push(routeToMechanicArticleRedirect(row.id));
}

const {
    qDataSource,
    loading,
    error,
    resultListEmpty,
    hasMoreResults,
    loadMoreArticles,
    total,
    fetchAggregation,
    mechanicArticlesResult,
} = useMechanicArticlesDataSource(
    computed(() => {
        return { text: fullTextSearchValue.value, product: props.product, asset: props.asset };
    }),
    selected,
    sorting,
    facet
);

const initialMechanicsLoading = computed(() => {
    return !mechanicArticlesResult.value;
});

const { t } = useI18n({ useScope: "global" });

const noResultButFiltersAreSet = ref(false);

watch(loading, () => {
    noResultButFiltersAreSet.value = resultListEmpty.value && selected.value.length > 0;
});

const noResultsErrorMessage = computed(() => {
    if (noResultButFiltersAreSet.value) {
        return t("mechanic.No articles found with the set filters Adjust the filter criteria to get more results");
    }
    if (props.fullTextSearchValue) {
        return t("core.No search results found");
    } else {
        return t("mechanic.No articles exist");
    }
});

function routeToMechanicArticleRedirect(mechanicNodeId: string) {
    return getPathToChildrenWithoutParents([
        {
            alias: CommonRouteAliases.product,
            namedParams: { [CommonRouteAliases.product]: props.product },
        },
        ...(props.asset
            ? [
                  {
                      alias: CommonRouteAliases.asset,
                      namedParams: { [CommonRouteAliases.asset]: props.asset },
                  },
              ]
            : []),
        {
            alias: CommonRouteAliases.searchToMechanicArticle,
            namedParams: { [CommonRouteAliases.searchToMechanicArticle]: mechanicNodeId },
        },
    ]);
}

const { sortOrderItems } = useMechanicArticlesListSort();
const { configMenuEntries } = useMechanicArticleListConfig();

const searchHitsText = computed(() => {
    return generateSearchHitsText(total.value);
});

const onOpenFilterHandler = (facetProp: Facet) => {
    facet.value = facetProp;
};

const openCollectionOverlay = (cell: QTableRowType) => {
    if (articleModelOverlay.value?.mechanicId === cell.id) {
        closeCollectionsOverlay();
        return;
    }

    articleModelOverlay.value = {
        mechanicId: cell.id,
        title: cell.title || cell.id,
    };
};

const closeCollectionsOverlay = () => {
    articleModelOverlay.value = undefined;
};
</script>

<style lang="scss" scoped>
.mechanic-articles-cell {
    padding: 0;
    align-items: center;

    a {
        width: 100%;
        height: 100%;
        align-items: center;
        margin: 0;
        padding-left: $spacing-l;
        padding-right: $spacing-l;
    }
}
.add-article-to-collection-button {
    max-height: 30px;
}
</style>
