import { RouteLocationRaw } from "vue-router";

export const IDENTIFY_PART = "identifyPart";
export const CLASSIFICATION = "classification";
export const IMAGE_RECOGNITION = "imageRecognition";

export interface IdentifyPartsItem {
    identifyPartsItemKey: string;
    titleKey: string;
    icon: string;
    textKey: string;
    buttonTextKey: string;
    productId: string;
    assetId: string | undefined;
    getButtonRoute?: () => RouteLocationRaw;
    isActive: boolean;
}

function createItems(): Array<IdentifyPartsItem> {
    return [
        {
            identifyPartsItemKey: IDENTIFY_PART,
            titleKey: "mechanic.Installation location",
            icon: "fa-light fa-sitemap icon-light-color fa-2xl",
            textKey: "mechanic.Identify components based on their location",
            buttonTextKey: "mechanic.Select installation location",
            productId: "",
            assetId: undefined,
            isActive: true,
        },
        // { Bilderkennung temporär deaktivieren
        //     identifyPartsItemKey: IMAGE_RECOGNITION,
        //     titleKey: "mechanic.Image recognition",
        //     icon: "fa-regular fa-camera icon-light-color fa-2xl",
        //     textKey: "mechanic.Identify the required component by photographing the nameplate or QR code with a mobile device",
        //     buttonTextKey: "mechanic.Photograph component",
        //     isActive: false,
        // },
    ];
}

let items: Array<IdentifyPartsItem> | null = null;

export function getIdentifyPartsItems() {
    if (items === null) {
        items = createItems();
    }
    return items;
}
