import gql from "graphql-tag";

export const basketForUserQuery = gql`
    query basketForUser($basketId: ID!) {
        basket(basketId: $basketId) {
            items {
                product {
                    id
                    title
                }
                amount
            }
        }
    }
`;

export const addBasketItemMutation = gql`
    mutation ($BasketId: ID!, $Amount: Float!, $ProductId: ID!) {
        basketAddItem(basketId: $BasketId, amount: $Amount, productId: $ProductId) {
            id
            items {
                id
                amount
            }
        }
    }
`;

export const addPriceRequest = gql`
    mutation ($ProductId: ID!) {
        addPriceRequest(articleId: $ProductId) {
            id
        }
    }
`;

export const getProductShopInfos = gql`
    query ($ProductId: ID!) {
        product(productId: $ProductId) {
            id
            price {
                price
                currency {
                    sign
                }
            }
            stock {
                stock
                stockStatus
            }
            showPriceOnRequest
        }
    }
`;
