<template>
    <q-list>
        <template v-for="(suggestion, index) in filteredSuggestions" :key="suggestion.title">
            <q-separator v-if="index > 0" class="q-mb-md" />
            <div>
                <div class="suggestionsTitles text-uppercase q-px-md q-pb-sm">
                    {{ $t(`core.${suggestion.title}`) }}
                </div>
                <q-item
                    clickable
                    dense
                    class="items-center q-px-mde"
                    v-for="item in suggestion.items"
                    @click="suggestionHandler(item, suggestion.type)"
                    :key="`${suggestion.type}${item.text}${item.searchTab?.alias}`"
                    :data-index="item.focusIndex"
                    :focused="item.focusIndex === selectedIndex"
                    :data-qs="`search-suggestion-${suggestion.type}-${item.text}${
                        item.searchTab ? `-${item.searchTab.alias}` : ''
                    }`"
                >
                    <div class="row flex-grow">
                        <div class="row flex-grow items-center">
                            <!-- Domain Suggestions -->
                            <template v-if="suggestion.type === 'domain'">
                                <i
                                    class="fa-regular fa-magnifying-glass icon-light-color margin-right-default"
                                    style="font-size: 16px"
                                />
                                <span> {{ item.text.slice(0, searchInput.length) }} </span>
                                <b>{{ item.text.slice(searchInput.length, item.text.length) }}</b>
                                <span class="link-color q-ml-xs text-italic">
                                    {{ ` ${$t("core.in")}&nbsp;` }}
                                    {{ item.searchTab?.displayText }}
                                </span>
                            </template>
                            <!-- Facet Suggestions -->
                            <template v-if="suggestion.type === 'facet'">
                                <i
                                    class="fa-regular fa-list-check icon-light-color margin-right-default"
                                    style="font-size: 16px"
                                />
                                <span class="text-italic q-mr-sm">{{ `${item.parentTitle}` }}</span>
                                <span> {{ item.text.slice(0, searchInput.length) }} </span>
                                <b>{{ item.text.slice(searchInput.length, item.text.length) }}</b>
                                <span class="link-color q-ml-xs text-italic">
                                    {{ ` ${$t("core.in")}&nbsp;` }}
                                    {{ item.searchTab?.displayText }}
                                </span>
                            </template>
                            <!-- History Suggestions -->
                            <template v-if="suggestion.type === 'history' && searchInput.length === 0">
                                <i
                                    class="fa-regular fa-rotate-right icon-light-color margin-right-default"
                                    style="font-size: 16px"
                                />
                                <span v-if="item.parentTitle" class="text-italic q-mr-sm">{{
                                    `${item.parentTitle}`
                                }}</span>
                                <span>
                                    <b>{{ item.text }}</b>
                                </span>
                                <span v-if="item.searchTab" class="link-color q-ml-xs text-italic">
                                    {{ ` ${$t("core.in")}&nbsp;` }}
                                    {{ item.searchTab.displayText }}
                                </span>
                            </template>
                        </div>
                        <div class="row flex-grow flex-end items-center">
                            <QitBtn
                                color="transparent"
                                icon="fa-regular fa-arrow-up-left icon-light-color"
                                size="12px"
                                :data-qs="`select-suggestion-text-${item.text}`"
                                @click.stop="() => emits('onSelectText', item.text, true)"
                            />
                        </div>
                    </div>
                </q-item>
            </div>
        </template>
    </q-list>
</template>

<script setup lang="ts">
import {
    SearchSuggestion,
    SearchSuggestionType,
    SuggestionListItem,
} from "@/shell/pages/header/search/header-search.types";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import { computed, toRefs } from "vue";

export interface HeaderSearchSuggestionsProps {
    suggestions: SearchSuggestion[];
    searchInput: string;
    selectedIndex: number;
}

const props = defineProps<HeaderSearchSuggestionsProps>();

const emits = defineEmits<{
    onSelect: [item: SuggestionListItem, type: SearchSuggestionType];
    onSelectText: [text: string, setFocus: boolean];
}>();
const { searchInput, suggestions } = toRefs(props);

const suggestionHandler = (item: SuggestionListItem, type: SearchSuggestionType) => {
    emits("onSelect", item, type);
};

const filteredSuggestions = computed(() => {
    //show only history suggestions if search input is empty
    if (searchInput.value.length === 0) return suggestions.value.filter((suggestion) => suggestion.type === "history");

    //exclude history suggestions if search input has value
    return suggestions.value.filter((suggestion) => suggestion.type !== "history");
});
</script>

<style lang="scss" scoped>
.suggestionsTitles {
    font-size: $smaller-font-size;
}
</style>
