<template>
    <div class="assembly-navigation">
        <div v-if="!data">
            <div class="assembly-nav-header">
                <div class="skeleton-square icon-button tabs-header-back-button">
                    <i class="hidden" />
                </div>
                <div class="skeleton-line-wrapper">
                    <div class="skeleton-line percent80 header-layer-name"></div>
                    <div class="skeleton-line percent50 header-layer-name margin-top-small"></div>
                </div>
            </div>
        </div>
        <ErrorMessage v-if="error" :error-message="$t('core.Oops, something went wrong')" />
        <div v-else class="content-wrapper">
            <div class="assembly-nav-header">
                <div
                    v-if="currentAssemblyId"
                    data-qs="assembly-navigation-go-to-previous-assembly-button"
                    class="icon-button tabs-header-back-button tabs-header-back-button-color"
                    @click="goToPreviousAssembly"
                >
                    <i class="fa-regular fa-arrow-left" />
                </div>
                <div class="header-layer-name">
                    <strong> {{ titleText }}</strong>
                    <div v-if="articleNumber" class="title-article-number">{{ articleNumber }}</div>
                </div>

                <div class="header-open-layer-link">
                    <router-link
                        class="router-link open-layer-link"
                        :to="getRouteToCurrentAssembly()"
                        data-qs="assembly-navigation-hide-overlay-button"
                        @click="$emit('hideOverlay')"
                    >
                        {{ $t("mechanic.Open layer") }}
                    </router-link>
                </div>
            </div>
            <div class="container" style="overflow: auto">
                <div class="item">
                    <component
                        :is="createMobileTile(mechanic).componentDefinition?.getComponent()"
                        v-bind="createMobileTile(mechanic).componentDefinition?.props"
                        @hide-overlay="$emit('hideOverlay')"
                        v-for="mechanic in dataSourceData"
                        :key="mechanic.id"
                        class="q-ma-sm"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, watch } from "vue";
import ErrorMessage from "@/shared/components/placeholder/error-message.vue";
import { useAssemblyTitleResult } from "../../helpers/assembly-title-result";
import { useMechanicsListQuery } from "../../helpers/mechanics-list-result";
import AssemblyNavItem from "./assembly-navigation-item.vue";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { Mechanic, MechanicSortFields, SortOrder } from "@/shared/services/graphql/generated/consumer-graph-types";
import { useProductDescriptionQuery } from "../mechanic.model";
import { encodeParamArrayToString } from "@/shared/environment/ability-route-helper";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { useAbilityRoute } from "@/shared/environment/ability-route";

const props = defineProps<{
    product: string;
    asset?: string;
    initialSelectedAssemblyPath?: string[];
}>();

const emit = defineEmits(["hideOverlay"]);

const focusedRowData = ref<Mechanic | undefined>(undefined);

const { getPathToChild } = useAbilityRoute();

const computedInitialSelectedAssemblyPath = computed(() => {
    if (props.initialSelectedAssemblyPath) {
        return props.initialSelectedAssemblyPath.length > 1
            ? [...props.initialSelectedAssemblyPath].slice(0, -1)
            : [""];
    } else {
        return [""];
    }
});

const currentAssemblyPath = ref<Array<string>>(computedInitialSelectedAssemblyPath.value);

const currentAssemblyId = computed(() => getLastIdFromPath(currentAssemblyPath.value));
const initialSelectedAssemblyId = computed(() => getLastIdFromPath(computedInitialSelectedAssemblyPath.value));

const dataSourceData = ref<Mechanic[]>([]);

const { error, data } = useMechanicsListQuery(
    computed(() => {
        return {
            assemblyId: currentAssemblyId.value,
            productId: props.product,
            assetId: props.asset,
            assemblyOnly: true,
            withPreviewImages: true,
            sortEntry: { field: MechanicSortFields.itemNumber, order: SortOrder.asc },
        };
    })
);

function createMobileTile(data: Mechanic) {
    return {
        componentDefinition: {
            getComponent: () => AssemblyNavItem,
            props: {
                mechanicData: data,
                product: props.product,
                asset: props.asset,
                isSelected: isSubAssemblySelected(data.assemblyId ?? ""),
                subAssemblyButtonClicked: createSubAssemblyButtonClickedCallback(data.assemblyId ?? ""),
                routeToAssembly: getRouteToSubAssembly(data.assemblyId ?? ""),
            },
            emits: {
                hideOverlay: () => {
                    emit("hideOverlay");
                },
            },
        },
    };
}

const productDescriptionFilterVariables = reactive({
    id: props.product,
    acceptedLanguages: getDataQueryLanguages(),
});

const { productTitle } = useProductDescriptionQuery(productDescriptionFilterVariables);

const { title, articleNumber } = useAssemblyTitleResult(
    currentAssemblyId,
    computed(() => props.product),
    computed(() => props.asset),
    computed(() => getDataQueryLanguages())
);

/**
 * gibt text für header an entweder:
 * titel des aktuellen produktes
 * titel der aktuellen baugruppe
 * leer
 */
const titleText = computed(() => {
    if (currentAssemblyPath.value.length > 1 && title) {
        return title.value;
    } else if (productTitle) {
        return productTitle.value;
    } else {
        return "";
    }
});

/**
 * fügt ein neues level an assemblyPath an und
 * geht ein level in de navigation nach unten
 * Ist ein callback, weil die SubKomponente ein einfachen ()=>void als onClick erwartet
 */
function createSubAssemblyButtonClickedCallback(assemblyId: string): () => void {
    return () => {
        currentAssemblyPath.value = currentAssemblyPath.value.concat(assemblyId);
    };
}

/**
 * Bekommt die letzte Id vom Pfad.
 * @param currentPath Array des aktuellen Pfads.
 * @returns Id vom Pfad array mit Position length -1 im Array.
 */
function getLastIdFromPath(currentPath: Array<string>): string {
    if (currentPath.length > 0) {
        return currentPath[currentPath.length - 1];
    } else {
        return "";
    }
}

/**
 * schneidet das letzte level von assemblyPath ab und
 * geht ein level in der navigation zurück
 */
function goToPreviousAssembly() {
    currentAssemblyPath.value.pop();
}

/**
 * springt in die partslist der aktuellen ebene
 */
function getRouteToCurrentAssembly() {
    return getPathToChild(CommonRouteAliases.mechanic, {
        [CommonRouteAliases.mechanic]: encodeParamArrayToString(currentAssemblyPath.value),
    });
}

/**
 * springt in die partslist der übergebenen ebene
 */
function getRouteToSubAssembly(id: string) {
    const newPath = currentAssemblyPath.value.concat(id);

    return getPathToChild(CommonRouteAliases.mechanic, {
        [CommonRouteAliases.mechanic]: encodeParamArrayToString(newPath),
    });
}

function isSubAssemblySelected(assemblyId: string): boolean {
    return assemblyId === initialSelectedAssemblyId.value;
}

// Nachdem Laden soll zu initialSelectedAssemblyId gescrollt werden
watch(data, () => {
    dataSourceData.value = data.value;

    const focusedItem = data.value.find((item) => item.assemblyId === initialSelectedAssemblyId.value);
    if (focusedItem) {
        focusedRowData.value = focusedItem;
    }
});
</script>

<style lang="scss" scoped>
.assembly-nav-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacing-m;
    padding-left: $spacing-l;
    padding-right: $spacing-l;
}

.header-open-layer-link {
    flex-shrink: 0;
    font-weight: $semi-bold;
    margin-left: $spacing-l;
}

.header-layer-name {
    font-size: 1.2em;
    flex-grow: 2;
}

.assembly-navigation {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
}

.content-wrapper {
    height: calc(100vh - 93px);
    display: flex;
    flex-direction: column;
}

.tabs-header-back-button {
    margin-right: $spacing-m;
    padding: $spacing-m;
}

.tabs-header-back-button-color {
    background: $light-background-color;
    &:hover {
        background: $grey-80;
    }
    &:active {
        background: $grey-60;
    }
}

.skeleton-line-wrapper {
    width: 100%;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
}

.item {
    flex: auto;
    position: relative;
}
</style>

<style lang="scss" scoped>
.title-article-number {
    font-size: $smaller-font-size;
    color: $light-text-color;
    margin-top: $spacing-s;
}

.open-layer-link {
    color: var(--q-link) !important;
}
</style>
