<template>
    <ListFrame
        :no-results="resultListEmpty"
        :loading="initialAssetsLoading"
        :error="error"
        :small-content-container="false"
        :large-content-container="true"
        :no-results-error-message="noResultsErrorMessage"
    >
        <template #filter="{ isMobile }">
            <ListFilterUrlSync
                v-model:selected="selected"
                sort-save-key="article_sort"
                :fetch-aggregation="fetchAggregation"
                :facet-collection-id="FacetCollection.articleSelection"
                :is-mobile-view="isMobile"
                :more-menu-entries="configMenuEntries"
                :sort-order-entries="sortOrderItems"
                @on-sort-selection-changed="onChangeSortOrder"
                @on-open-filter="onOpenFilterHandler"
            />
        </template>
        <template v-if="showNeedQualificationBanner" #banner>
            <NeedQualificationBanner
                icon="fa-kit fa-qit-missing-asset warning-color"
                :text="$t('mechanic.Optimize the search results by defining the search context')"
                :button-text="$t('mechanic.Optimize results')"
                @on-button-click="showOverlay"
            />
        </template>
        <template #count>
            {{ searchHitsText }}
        </template>
        <template #content>
            <Table
                data-qs="articles-list-table"
                :q-data-source="qDataSource"
                :data-loading="loading"
                :no-data-text="noResultsErrorMessage"
                :has-more-results="hasMoreResults"
                :custom-sticky-right-cell-column-name="ADD_TO_COLLECTION_COL_NAME"
                :custom-cells="[QIT_PREVIEW_IMAGE_COLUMN]"
                :mobile-action-column-name="ADD_TO_COLLECTION_COL_NAME"
                sticky-last-column
                @on-load-more="loadMoreArticles()"
                @row-click="onRowClick"
            >
                <template #cell-content="{ row, cell }">
                    <router-link :to="routeToCommonArticle(row.id)" class="flex">
                        <q-item-label>{{ cell }}</q-item-label>
                    </router-link>
                </template>
                <template #custom-cell-column-name="{ row }">
                    <ShowIfHasAccess
                        :resource="AccessResource.collections"
                        :action="AccessAction.write"
                        :ignore-conditions="true"
                    >
                        <QitBtn
                            color="secondary"
                            size="sm"
                            padding="sm"
                            icon="fak fa-regular fa-fa-regular-bookmark-circle-plus default-grey-icon-color"
                            class="add-article-to-collection-button"
                            :data-qs="`add-article-to-collection-button-${row.id}`"
                            @click="openCollectionOverlay(row)"
                            @click.stop
                        >
                            <q-tooltip>{{ $t("collections.Add to Collection") }}</q-tooltip>
                        </QitBtn>
                    </ShowIfHasAccess>
                </template>
                <template #mobile-action="{ row }">
                    <ShowIfHasAccess
                        :resource="AccessResource.collections"
                        :action="AccessAction.write"
                        :ignore-conditions="true"
                    >
                        <div class="row flex-grow flex-end q-mr-md">
                            <QitBtn
                                color="secondary"
                                size="sm"
                                padding="sm"
                                icon="fak fa-regular fa-fa-regular-bookmark-circle-plus default-grey-icon-color"
                                class="add-article-to-collection-button"
                                :data-qs="`add-article-to-collection-button-${row.id}`"
                                @click="openCollectionOverlay(row)"
                                @click.stop
                            >
                                <q-tooltip>{{ $t("collections.Add to Collection") }}</q-tooltip>
                            </QitBtn>
                        </div>
                    </ShowIfHasAccess>
                </template>
            </Table>
        </template>
    </ListFrame>
    <Dialog
        v-if="showNeedQualificationBanner"
        v-model="isNeedQualificationBannerOverlayVisible"
        position="left"
        :title="$t('mechanic.Select installation location')"
        icon="fa-regular fa-code-branch warning-color"
        :width="500"
    >
        <NeedQualificationWizard
            :level-definition="qualificationLevels"
            :selection-data="qualificationSearchData"
            :emit-last-level-selection="true"
            @hide="hideOverlay"
            @select="setContext"
        />
    </Dialog>
    <CollectionArticleOverlay
        v-if="articleModelOverlay"
        :article="articleModelOverlay"
        @on-close="closeCollectionsOverlay"
        @on-save-succeeded="closeCollectionsOverlay"
    />
</template>

<script setup lang="ts">
/* Komponente, die eine Liste von Artikeln ohne Einbauort anzeigt. */
import { computed, Ref, ref, watch } from "vue";
import { useArticleListConfig, useArticlesListSort } from "./articles-list.model";
import { useI18n } from "vue-i18n";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { Facet, FacetCollection } from "@/shared/facets/facets.model";
import { useSelectedFacets } from "@/shared/facets/composables/selected-facets";
import ListFilterUrlSync from "@/shared/list-filter/list-filter-renderer-url-sync.vue";
import ListFrame from "@/shared/list-frame/list-frame.vue";
import Table from "@/shared/components/table/table.vue";
import Dialog from "@/shared/components/dialog/title-content-footer-dialog.vue";
import NeedQualificationBanner from "@/shared/need-qualification/components/need-qualification-banner.vue";
import NeedQualificationWizard from "@/shared/need-qualification/components/need-qualification-wizard.vue";
import { SelectionLevelDefinition } from "@/shared/need-qualification/components/need-qualification-wizard.model";
import { QualificationLevelSelectionResult } from "@/shared/environment/ability.types";
import { stringify } from "qs";
import { useRoute, useRouter } from "vue-router";
import { generateSearchHitsText } from "@/shared/utils/total-formatter";
import {
    ADD_TO_COLLECTION_COL_NAME,
    useArticlesDataSource,
} from "@/abilities/mechanic/pages/article-search/articles-list-data-source";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import QitBtn from "@/shared/components/buttons/qit-button.vue";
import CollectionArticleOverlay, {
    ArticleModelOverlay,
} from "@/abilities/collections/components/overlay/collection-article-overlay.vue";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control.ts";
import ShowIfHasAccess from "@/shared/access-control/components/show-if-has-access.vue";
import { QIT_PREVIEW_IMAGE_COLUMN, QTableRowType } from "@/shared/components/table/q-data-source";

const props = defineProps<{
    fullTextSearchValue: string;
}>();

const facet = ref<Facet | undefined>();
const articleModelOverlay = ref<ArticleModelOverlay | undefined>();
const isNeedQualificationBannerOverlayVisible: Ref<boolean> = ref(false);

const route = useRoute();
const router = useRouter();
const { getPathToChild, getPathToChildWithoutParents } = useAbilityRoute();

const context = computed(() => {
    return { text: props.fullTextSearchValue };
});

const { sorting, onChangeSortOrder, selected } = useSelectedFacets();

const {
    qDataSource,
    loading,
    error,
    resultListEmpty,
    hasMoreResults,
    loadMoreArticles,
    FIRST_LOAD,
    total,
    fetchAggregation,
    articlesResult,
} = useArticlesDataSource(context, selected, sorting, facet);

const initialAssetsLoading = computed(() => {
    return !articlesResult.value;
});

const noResultButFiltersAreSet = ref(false);

watch(loading, () => {
    noResultButFiltersAreSet.value = resultListEmpty.value && selected.value.length > 0;
});

function onRowClick(row: QTableRowType) {
    router.push(routeToCommonArticle(row.id));
}

const { t } = useI18n({ useScope: "global" });

const noResultsErrorMessage = computed(() => {
    if (noResultButFiltersAreSet.value) {
        return t("mechanic.No articles found with the set filters Adjust the filter criteria to get more results");
    }
    if (context.value.text) {
        return t("core.No search results found");
    } else {
        return t("mechanic.No articles exist");
    }
});

/**
 * Returns Route to Article Detail Page without Context (Einbauort)
 */
function routeToCommonArticle(articleNodeId: string) {
    return getPathToChildWithoutParents(CommonRouteAliases.commonArticle, {
        [CommonRouteAliases.commonArticle]: articleNodeId,
    });
}

const { sortOrderItems } = useArticlesListSort();
const { configMenuEntries } = useArticleListConfig();

function showOverlay() {
    isNeedQualificationBannerOverlayVisible.value = true;
}

function hideOverlay() {
    isNeedQualificationBannerOverlayVisible.value = false;
}

const qualificationLevels = computed((): Array<SelectionLevelDefinition> => {
    return [
        {
            aliases: [CommonRouteAliases.product, CommonRouteAliases.asset],
            levelText: "",
        },
    ];
});

const qualificationSearchData = computed<QualificationLevelSelectionResult>(() => {
    return {
        text: context.value.text,
    };
});

function setContext(selectionResult: QualificationLevelSelectionResult) {
    const searchContext = stringify(selectionResult, { encode: false });
    const path = getPathToChild(
        CommonRouteAliases.search,
        {
            [CommonRouteAliases.search]: searchContext,
        },
        route.query
    );
    router.push(path);
}

const showNeedQualificationBanner = computed(() => {
    return (
        qDataSource.value.rows && (qDataSource.value.rows.length >= FIRST_LOAD || qDataSource.value.rows.length === 0)
    );
});

const searchHitsText = computed(() => {
    return generateSearchHitsText(total.value);
});

const onOpenFilterHandler = (facetProp: Facet) => {
    facet.value = facetProp;
};

const openCollectionOverlay = (cell: QTableRowType) => {
    if (articleModelOverlay.value?.articleId === cell.id) {
        closeCollectionsOverlay();
        return;
    }

    articleModelOverlay.value = { articleId: cell.id, title: cell.title || cell.id };
};

const closeCollectionsOverlay = () => {
    articleModelOverlay.value = undefined;
};
</script>

<style lang="scss" scoped>
.add-article-to-collection-button {
    max-height: 30px;
}
</style>
/
