import { useAbilityStore } from "@/shared/store/ability.store";
import { storeToRefs } from "pinia";
import { Ability, SearchTab } from "@/shared/environment/ability.types";
import { computed, ComputedRef, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { useAbilityRoute } from "@/shared/environment/ability-route";

const searchTabs = ref<SearchTab[]>([]);

export const useSearchTabsFromAbilities = () => {
    const abilityStore = useAbilityStore();
    const { abilities } = storeToRefs(abilityStore);
    const route = useRoute();

    const { getCurrentSearchParams } = useAbilityRoute();

    const searchTabsAll: ComputedRef<SearchTab[]> = computed(() => {
        const tabs: SearchTab[] = abilities.value
            .filter((ability: Ability) => {
                return !!ability.getSearchTabs;
            })
            .flatMap((x: Ability) => x.getSearchTabs!());

        return tabs;
    });

    watch(
        () => route.fullPath,
        async () => {
            const { searchParam } = await getCurrentSearchParams();
            const { asset, product } = searchParam;

            //we have to convert to any because of a typescript bug with Type SearchTab
            if (!asset && !product) {
                searchTabs.value = searchTabsAll.value.map((x: SearchTab) => x) as any;
                return;
            }

            if (asset)
                searchTabs.value = searchTabsAll.value
                    .filter(
                        (x) => x.alias !== CommonSearchTabAliases.products && x.alias !== CommonSearchTabAliases.assets
                    )
                    .map((x) => x) as any;
            else if (product)
                searchTabs.value = searchTabsAll.value
                    .filter((x) => x.alias !== CommonSearchTabAliases.products)
                    .map((x) => x) as any;
            else searchTabs.value = searchTabsAll.value.map((x) => x) as any;
        },
        { immediate: true }
    );

    return {
        searchTabs,
    };
};
