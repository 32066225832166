<template>
    <ExpansionPanel
        icon="fa-regular fa-wrench icon-light-color"
        :label="t('activities.Perform activities')"
        v-if="relatedActivities && relatedActivities?.length > 0"
    >
        <template v-if="!isLessThanMedium" #header-right>
            <router-link :to="getPathToChild(CommonRouteAliases.activities, {})" class="router-link">
                <QitButton flat :label="$t('activities.Show all activities')" />
            </router-link>
        </template>
        <div data-qs="related-activities-widget">
            <div class="fit row" :class="{ 'q-col-gutter-md': !isLessThanMedium, gutter: isLessThanMedium }">
                <div
                    class="col-md-4 col-xs-12"
                    v-for="(relatedActivity, index) in relatedActivities"
                    :key="relatedActivity.id"
                >
                    <router-link :to="getPath(relatedActivity.id)">
                        <ListCard
                            card-type="small"
                            :title="relatedActivity.teaser"
                            :locale-tag="relatedActivity.language"
                            :test-id="`related-activities-${index}`"
                        >
                            <template #image>
                                <div class="items-center row">
                                    <q-icon class="fa-light icon-light-color fa-wrench fa" size="20px" />
                                </div>
                            </template>
                        </ListCard>
                    </router-link>
                </div>
            </div>
            <div v-if="isLessThanMedium" class="flex flex-grow justify-center q-mt-lg">
                <router-link :to="getPathToChild(CommonRouteAliases.activities, {})" class="router-link">
                    <QitButton flat :label="$t('activities.Show all activities')" />
                </router-link>
            </div>
        </div>
    </ExpansionPanel>
</template>

<script lang="ts" setup>
import { WidgetContext } from "@/shared/environment/widgets/widget-context";
import { computed, ComputedRef, onMounted } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { ActivityEdge } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { useI18n } from "vue-i18n";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import {
    generateRelatedActivitiesQuery,
    RelatedActivitiesContext,
} from "@/abilities/activities/graphql/activity.query";
import { SearchContext } from "@/shared/search/search.types";
import ExpansionPanel from "@/shared/components/expansion/expansion-panel.vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useActivityRoute } from "@/shared/router/activity-route";
import ListCard from "@/shared/components/card/list-card.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size.ts";

const props = defineProps<{
    widgetContext: WidgetContext;
}>();

const { t } = useI18n();
const { getPathToChild } = useAbilityRoute();
const { calculatePathForActivitiesSubalias } = useActivityRoute();
const { isLessThanMedium } = useScreenSize();

const getContextAndId = (): { context: RelatedActivitiesContext; id: string } => {
    if (props.widgetContext.__type === "asset") {
        return {
            context: "asset",
            id: `${props.widgetContext.product}_${props.widgetContext.asset}`,
        };
    }
    if (props.widgetContext.__type === "commonArticle") {
        return { context: "article", id: props.widgetContext.articleId };
    }
    if (props.widgetContext.__type === "mechanicArticle") {
        return { context: "article", id: props.widgetContext.mechanicArticleId };
    }
    if (props.widgetContext.__type === "product") {
        return { context: "product", id: props.widgetContext.productId };
    }
    throw Error(`Unknown widget context: ${props.widgetContext.__type}`);
};

const getPath = (activityId: string) => {
    const { context, id } = getContextAndId();
    let searchContext: SearchContext = {};
    if (context === "product") searchContext.product = id;
    else if (context === "article") searchContext.article = id;
    else if (context === "asset") {
        const [product, asset] = id.split("_");
        searchContext = { product, asset };
    }

    return calculatePathForActivitiesSubalias(CommonRouteAliases.activityOverview, activityId, searchContext);
};
const { load, result } = useLazyQuery(generateRelatedActivitiesQuery(getContextAndId()?.context!, {}, {}), {});

onMounted(() => {
    load(generateRelatedActivitiesQuery(getContextAndId()?.context!, {}, {}), {
        id: getContextAndId()?.id!,
        first: 3,
        acceptedLanguages: getDataQueryLanguages(),
    });
});

const relatedActivities: ComputedRef<{ id: string; teaser: string; language: string }[] | undefined> = computed(() => {
    if (!result.value || Object.keys(result.value).length === 0) return;

    const key = Object.keys(result.value)[0];

    return result?.value[key]?.relatedActivities?.activities?.map((x: ActivityEdge) => {
        return {
            id: x.node.id,
            teaser: x.node.localizations?.title!,
            language: x.node.language.toUpperCase(),
        };
    });
});
</script>

<style lang="scss" scoped>
.gutter {
    gap: 12px;
}
</style>
