import LanguageSelector from "@/shared/components/menu-items/language-selector.vue";
import InfoMenu from "@/shared/components/menu-items/info-menu.vue";
import NotificationsMenu from "@/shared/components/menu-items/notifications/pages/notifications-menu.vue";
import UserMenu from "@/shared/components/menu-items/user-menu.vue";
import { insertObjectAtPosition } from "../helpers/sort-helper";
import { MenuEntryWithMetaData } from "@/shared/environment/ability.types";
import { MenuEntry } from "@/shared/components/menu/menu.model";
import { computed, ComputedRef } from "vue";
import { useStaticPages } from "@/shared/composables/static-pages";

export function useMenuItems(): ComputedRef<MenuEntry[]> {
    const { staticPages, loading: staticPageLoading } = useStaticPages();

    return computed<MenuEntry[]>(() => {
        if (staticPageLoading.value) return [];

        const staticMenuItems: MenuEntryWithMetaData[] = [
            {
                position: 1,
                entry: {
                    visible: true,
                    component: {
                        getComponent: () => LanguageSelector,
                    },
                },
            },
            {
                position: 2,
                entry: {
                    visible: staticPages.value.length > 0,
                    component: {
                        getComponent: () => InfoMenu,
                        props: {
                            icon: "fa-regular fa-circle-question icon-color",
                            mobileIcon: "fa-regular fa-circle-question text-on-navbar-color",
                            menuTitleTranslationKey: "core.Help",
                            staticPages: staticPages.value,
                        },
                    },
                },
            },
            {
                position: 3,
                entry: {
                    visible: true,
                    component: {
                        getComponent: () => NotificationsMenu,
                        props: {
                            icon: "fa-regular fa-bell icon-color",
                            mobileIcon: "fa-regular fa-bell text-on-navbar-color",
                            menuTitleTranslationKey: "core.Notifications",
                        },
                    },
                },
            },
            {
                position: 4,
                entry: {
                    visible: true,
                    component: {
                        getComponent: () => UserMenu,
                        props: {
                            icon: "fa-regular fa-circle-user icon-color",
                            mobileIcon: "fa-regular fa-circle-user text-on-navbar-color",
                            menuTitleTranslationKey: "core.Profile",
                        },
                    },
                },
            },
        ];

        const menuItems = [...staticMenuItems];
        const menuEntries: MenuEntryWithMetaData[] = [];
        menuItems.forEach((item) => {
            insertObjectAtPosition<MenuEntryWithMetaData>(menuEntries, item, item.position);
        });
        return menuEntries.map((item) => item.entry);
    });
}
