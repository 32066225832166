import { TextObject } from "@/shared/i18n/translation-types";
import { AccessOptions } from "@/shared/environment/ability.types";
import { useAbilityStore } from "@/shared/store/ability.store";
import { useAbilityRoute } from "@/shared/environment/ability-route";

export interface NavigationEntry {
    index: number;
    icon: string;
    title: TextObject;
    isVisible?: () => boolean;
    path: string;
    alias?: string;
    pathWithoutParam?: string;
    access?: AccessOptions;
    externalLink?: string;
    abilityAlias: string;
    queryParams?: boolean;
}

export function createNavigationEntries() {
    const result: Array<NavigationEntry> = [];

    const abilityStore = useAbilityStore();
    const { findRouteForAlias } = useAbilityRoute();

    abilityStore.abilities.forEach((ability) => {
        ability.getNavigationMenuEntries?.().forEach((menuEntry) => {
            if (menuEntry.internalRoute) {
                const route = findRouteForAlias(abilityStore.abilities, menuEntry.internalRoute.alias);
                if (route?.isRoot) {
                    const pathWithoutParams = "/" + route.alias;
                    let path = pathWithoutParams;

                    // Leere Parameter hinzufügen
                    route.params.forEach(() => {
                        path += "/-";
                    });

                    result.push({
                        index: result.length,
                        alias: route.alias,
                        icon: menuEntry.icon,
                        path: path,
                        pathWithoutParam: pathWithoutParams,
                        title: menuEntry.title,
                        isVisible: menuEntry.isVisible,
                        ...(route.access ? { access: route.access } : {}),
                        ...(menuEntry.internalRoute.access ? { access: menuEntry.internalRoute.access } : {}),
                        abilityAlias: ability.alias,
                        queryParams: menuEntry.internalRoute.queryParams,
                    });
                }
            } else if (menuEntry.externalRoute) {
                result.push({
                    index: result.length,
                    icon: menuEntry.icon,
                    externalLink: menuEntry.externalRoute.link,
                    path: menuEntry.externalRoute.link,
                    title: menuEntry.title,
                    isVisible: menuEntry.isVisible,
                    ...(menuEntry.externalRoute.access ? { access: menuEntry.externalRoute.access } : {}),
                    abilityAlias: ability.alias,
                });
            }
        });
    });

    return result;
}
