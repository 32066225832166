import { Ability } from "@/shared/environment/ability.types";
import { PlayGroundAbility } from "@/abilities/playground/config/playground.ability";
import { DashboardAbility } from "@/abilities/dashboard/config/dashboard.ability";
import { ProductAndAssetAbility } from "@/abilities/product-and-asset/config/product-and-asset.ability";
import { MechanicAbility } from "@/abilities/mechanic/config/mechanic.ability";
import { DocumentationAbility } from "@/abilities/documentation/config/documentation.ability";
import { DataProcessAbility } from "@/abilities/data-process/config/data-process.ability";
import { SearchAbility } from "@/abilities/search/config/search.ability";
import { AdminLinkAbility } from "@/abilities/admin-link/config/admin-link.ability";
import { StaticPagesAbility } from "@/abilities/static-pages/config/static-pages.ability";
import { CollectionsAbility } from "@/abilities/collections/config/collections.ability";
import { ActivitiesAbility } from "@/abilities/activities/config/activities.ability";
import { GoAbility } from "@/abilities/go/config/go.ability";
import { FdiShopAbility } from "@/abilities/fdi-shop/config/fdi-shop.ability";

export function createAbilityList(): Array<Ability> {
    // Be careful when changing the order. The order of the Abilities also determines the order of the menu items and widgets and search tabs.
    // This behavior should be changed in the future.
    return [
        new DashboardAbility(),
        new ProductAndAssetAbility(),
        new MechanicAbility(),
        new DocumentationAbility(),
        new ActivitiesAbility(),
        new CollectionsAbility(),
        new DataProcessAbility(),
        new PlayGroundAbility(),
        new SearchAbility(),
        new AdminLinkAbility(),
        new StaticPagesAbility(),
        new GoAbility(),
        new FdiShopAbility(),
    ];
}

type AbilityFilter = (ability: Ability) => Promise<boolean>;

const abilityFilters: Array<AbilityFilter> = [];

export function addAbilityFilter(filter: AbilityFilter) {
    abilityFilters.push(filter);
}
