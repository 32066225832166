<template>
    <div
        v-if="showBreadCrumbsFromRoute"
        class="row"
        :class="{
            'q-pl-sm bg-white': isXSmall,
            'q-mt-lg': isLargerThanMedium,
            'q-mt-md': !isLargerThanMedium && !isXSmall,
        }"
    >
        <BreadCrumbsFromRoute />
    </div>
    <div
        v-if="showTitle || slots.tabs || slots.rightMenu || leftIcon"
        class="row page-header-container"
        :class="{
            'full-width': !isXSmall,
            'q-pl-sm bg-white': isXSmall,
            'q-pb-md': isLargerThanMedium && !slots.tabs,
            'q-mt-md': !showBreadCrumbsFromRoute && isLargerThanMedium,
            'q-pt-sm': !showBreadCrumbsFromRoute,
            'q-py-xs': !showBreadCrumbsFromRoute && isXSmall,
            'q-pb-sm': !slots.tabs,
        }"
        :style="isXSmall ? 'padding-left: 8px;' : 'min-height: 40px'"
    >
        <div
            v-if="showTitle || slots.tabs || slots.rightMenu"
            class="flex full-width flex-grow qit-text-ellipsis"
            :class="{ 'no-wrap': !slots.headerAfter, 'flex-grow': slots.rightMenu }"
        >
            <div
                v-if="(leftIcon && !onlyShowLeftIconMobile) || (onlyShowLeftIconMobile && $q.screen.lt.sm)"
                class="col-auto flex items-center q-mr-sm left-icon-container"
            >
                <q-icon
                    flat
                    round
                    class="cursor-pointer"
                    size="xs"
                    :name="leftIcon"
                    @click="emit('onLeftIconClick')"
                    data-qs="page-header-left-icon"
                >
                    <q-tooltip v-if="leftIconTooltipText">{{ leftIconTooltipText }}</q-tooltip>
                </q-icon>
            </div>
            <div
                v-if="showTitle"
                class="flex qit-text-ellipsis"
                :class="{
                    'q-mr-md': isLargerThanMedium,
                    'flex-grow': slots.rightMenu && !slots.tabs && !isXSmall,
                    'col-auto': !isLargerThanMedium,
                }"
            >
                <h1 v-if="loading" class="heading-style skeleton-square q-my-none" style="height: 26px; width: 275px" />
                <h1 v-else class="heading-style qit-text-ellipsis q-my-none" data-qs="page-header-title">
                    {{ title }}
                </h1>
            </div>
            <div v-if="slots.headerAfter && !isXSmall && !loading" class="header-after col-auto flex q-mx-sm">
                <slot name="headerAfter" />
            </div>
            <template v-if="slots.tabs && !isXSmall">
                <slot name="tabs" />
            </template>
            <div
                v-if="slots.rightMenu && !isXSmall"
                class="row flex-grow flex-end q-mr-md no-wrap"
                :class="{ 'q-mr-lg': isLargerThanMedium }"
            >
                <slot name="rightMenu" />
            </div>
        </div>
        <div class="row header-description ellipsis-2-lines" v-if="!loading && slots.headerDescription">
            <slot name="headerDescription" />
        </div>
    </div>
    <div v-if="slots.headerAfter && isXSmall" class="row bg-white q-pl-sm header-after q-pb-xs">
        <slot name="headerAfter" />
    </div>
    <div v-if="slots.tabs && isXSmall" class="row bg-white">
        <slot name="tabs" />
    </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from "vue";
import BreadCrumbsFromRoute from "@/shared/components/breadcrumbs/breadcrumbs-from-route.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";

const props = defineProps<{
    title?: string;
    loading?: boolean;
    leftIcon?: string;
    leftIconTooltipText?: string;
    onlyShowLeftIconMobile?: boolean;
    showBreadCrumbsFromRoute?: boolean;
    showTitleOnlyMobile?: boolean;
}>();

const emit = defineEmits<{
    (e: "onLeftIconClick"): void;
}>();

const slots = useSlots();
const { isXSmall, isLargerThanMedium } = useScreenSize();

const showTitle = computed(() => {
    return (
        (props.showTitleOnlyMobile && isXSmall.value) || (!props.showTitleOnlyMobile && (props.title || props.loading))
    );
});
</script>

<style lang="scss" scoped>
.page-header-container {
    background-color: $light-background-color;

    .header-after {
        margin-top: 12px;
    }

    .header-description {
        color: $light-text-color;
    }

    .left-icon-container {
        max-height: 34px;
    }
}

.header-after {
    font-weight: 600;
    font-size: 0.85rem;
    color: rgb(140, 140, 140);
}

.heading-style {
    font-size: 24px;

    .screen--xs & {
        max-width: calc(100vw - 38px); // 100 viewport width - leftIcon width
    }
}
</style>
