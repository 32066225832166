import { useAssetNames } from "@/abilities/product-and-asset/composables/asset-names";
import { getI18n } from "@/shared/i18n/i18n";
import { computed } from "vue";
import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";

/**
 * Get the very special Text "Products & Assets"
 */
export const useProductAndAssetAndText = () => {
    const productName = useProductNames("plural");
    const assetName = useAssetNames("plural");
    const i18n = getI18n();

    return computed(() => {
        if (productName.value && assetName.value) {
            //@ts-ignore
            return i18n.global.t("product-and-asset.{productsName} & {assetsName}", {
                productsName: productName.value,
                assetsName: assetName.value,
            });
        }
        return "";
    });
};
