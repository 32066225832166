import { Ability, NavigationMenuEntry, Route, Widget } from "@/shared/environment/ability.types";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { AccessAction, AccessFeature, AccessResource } from "@/shared/access-control/access-control";
import CollectionsWidget from "@/abilities/collections/widgets/collection.widget.vue";
import { keyText } from "@/shared/i18n/translation-types";

export const COLLECTIONS_ROUTE_ALIAS = CommonRouteAliases.collections;

export class CollectionsAbility implements Ability {
    alias = COLLECTIONS_ROUTE_ALIAS;
    getNavigationMenuEntries = (): Array<NavigationMenuEntry> => [
        {
            icon: "fa-regular fa-bookmark",
            title: keyText("collections.Collections"),
            internalRoute: {
                alias: COLLECTIONS_ROUTE_ALIAS,
            },
        },
    ];
    getRoutes = (): Array<Route> => [
        {
            alias: COLLECTIONS_ROUTE_ALIAS,
            params: [COLLECTIONS_ROUTE_ALIAS],
            isRoot: true,
            getComponent: () => import("@/abilities/collections/pages/collections-main/collections-main.vue"),
            access: {
                accessAction: AccessAction.query,
                accessResource: AccessResource.collections,
                accessFeature: AccessFeature.collections,
            },
        },
    ];
    getWidgets = (): Array<Widget> => [
        {
            widgetContextAlias: ["dashboard"],
            widgetComponent: CollectionsWidget,
        },
    ];
}
