<template>
    <div class="loading-tile" :class="{ 'large-loading-tile': isLarge }">
        <div class="image skeleton-square" />
        <div class="information">
            <h4 class="title skeleton-square" style="width: 100px; height: 20px" />
            <div class="property-view skeleton-square" style="width: 200px; height: 14px" />
        </div>
    </div>
    <q-resize-observer @resize="onResize"></q-resize-observer>
</template>

<script setup lang="ts">
import { ref } from "vue";

const isLarge = ref(true);

const onResize = (value: { width: number }) => {
    isLarge.value = value.width > 930;
};
</script>

<style lang="scss" scoped>
.loading-tile {
    display: flex;
    margin: #{$spacing-m + $spacing-s};
    height: 131px;

    &.large-loading-tile {
        height: inherit;
    }

    .image {
        height: 75px;
        width: 100px;
        margin-right: $spacing-m;
        background: $grey-95;
    }
    .information {
        flex-grow: 1;
        .title {
            margin-top: 0;
            margin-bottom: $spacing-m;
        }

        .property-view {
            display: flex;
            flex-wrap: wrap;
            max-width: 100%;
        }

        .property-view:deep(.property) {
            flex-basis: 50%;
            max-width: $property-max-width;
        }
    }
}
</style>
