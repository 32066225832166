<template>
    <ListCard
        :title="advancedProductOrAsset?.getTitle()"
        :image-separator="!!advancedProductOrAsset?.getPreviewImage()"
        :test-id="testId"
    >
        <template #image>
            <PreviewImage :image-src="advancedProductOrAsset?.getPreviewImage()" :fallback-icon="fallbackIcon" />
        </template>
        <template #content>
            <div class="properties row">
                <PropertyView :items="productDisplayProperties" :fixed-data-display-count="4" />
            </div>
        </template>
    </ListCard>
</template>

<script lang="ts" setup>
import PropertyView from "@/shared/components/views/property-view.vue";
import { computed, ComputedRef, ref } from "vue";
import { AdvProduct } from "../../data-objects/advanced-product";
import { PropertyItem } from "@/shared/components/views/property-item.model";
import { useDataDisplayConfig } from "@/shared/data-display-config/composable/data-display-config";
import { AdvAsset } from "../../data-objects/advanced-asset";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { DataDisplayConfigId } from "@/shared/data-display-config/composable/data-display.model";
import { computedText, useTextObjectTranslation } from "@/shared/i18n/translation-types";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useProductNames } from "@/abilities/product-and-asset/composables/product-names";
import PreviewImage from "@/shared/components/image-item/preview-image.vue";
import ListCard from "@/shared/components/card/list-card.vue";

const props = defineProps<{
    advancedProductOrAsset: AdvProduct | AdvAsset | undefined;
    fallbackIcon: string;
    testId?: string;
}>();

const { getPathToChildWithoutParents } = useAbilityRoute();
const isAsset = props.advancedProductOrAsset?.__typename === "Asset";
const { getDataDisplayPropertiesByConfig } = useDataDisplayConfig(
    isAsset ? DataDisplayConfigId.asset : DataDisplayConfigId.product
);

const productId = ref(
    props.advancedProductOrAsset && "productId" in props.advancedProductOrAsset
        ? props.advancedProductOrAsset.productId
        : ""
);

const productText =
    props.advancedProductOrAsset?.localizations?.title ?? props.advancedProductOrAsset?.teasers?.title ?? "";

const productName = useProductNames("singular");
const { trans } = useTextObjectTranslation();

const productPropertyItem: PropertyItem = {
    title: trans(computedText(productName)),
    key: "productid",
    value: productText ? productText : "",
    ...(isAsset && {
        getRoute: productId.value
            ? () => {
                  return getPathToChildWithoutParents(CommonRouteAliases.product, {
                      [CommonRouteAliases.product]: productId.value as string,
                  });
              }
            : undefined,
    }),
};

const productDisplayProperties: ComputedRef<PropertyItem[]> = computed(() => {
    if (isAsset) {
        return [productPropertyItem].concat(getDataDisplayPropertiesByConfig(props.advancedProductOrAsset));
    }
    return getDataDisplayPropertiesByConfig(props.advancedProductOrAsset);
});
</script>

<style lang="scss" scoped>
:deep(.property) {
    margin-right: $spacing-xxl;
}
</style>
