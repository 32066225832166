import { computed } from "vue";
import { useGetConfigProductAndAssetQuery } from "@/shared/services/graphql/generated/consumer-graph-types";

const defaultValue = {
    productName: "PRODUCTS",
    assetName: "ASSETS",
};

export function useLoadConfigProductAndAsset() {
    const { loading, result } = useGetConfigProductAndAssetQuery(
        computed(() => {
            return {
                fetchPolicy: "cache-first",
            };
        })
    );

    const configProductAndAsset = computed(() => {
        if (!loading.value) {
            return result.value?.config?.configProductAndAsset ?? defaultValue;
        } else {
            return undefined;
        }
    });

    return {
        loading,
        configProductAndAsset,
    };
}
