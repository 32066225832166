<template>
    <router-link class="router-link" :to="linkToDocumentationDetailPage" :data-qs="`doc-list-card-${document.id}`">
        <ListCard :title="document.title" :highlight="highlight" :locale-tag="document.language" :index="index">
            <!-- @vue-ignore -->
            <template v-if="document.rootContentMapTitle" #subtitle>
                <div class="col subtitle light-text-color qit-text-ellipsis">
                    <!-- @vue-ignore -->
                    {{ $t("documentation.in {path}", { path: document.rootContentMapTitle }) }}
                </div>
            </template>
            <template #image>
                <div class="image">
                    <i v-if="isTopic" class="fa-file-lines fa-light icon-light-color fa-3x" />
                    <i v-else class="fa-book fa-light icon-color fa-3x" />
                </div>
            </template>
            <template #content>
                <div v-if="document.propertyItems" class="row properties">
                    <PropertyView :items="document.propertyItems" :max-property-width="180" />
                </div>
            </template>
            <template #buttons>
                <ShowIfHasAccess
                    :resource="AccessResource.collections"
                    :action="AccessAction.write"
                    :ignore-conditions="true"
                >
                    <div class="q-mt-md q-mr-md">
                        <QitButton
                            @click.stop.prevent
                            icon="fak fa-regular fa-fa-regular-bookmark-circle-plus default-grey-icon-color"
                            @click="onOpenOverlayRight"
                            color="secondary"
                            padding="sm"
                            size="12px"
                            :data-qs="`document-open-add-to-collection-button-${document.id}`"
                        >
                            <q-tooltip>{{ $t("documentation.Add to Collection") }}</q-tooltip>
                        </QitButton>
                    </div>
                </ShowIfHasAccess>
                <MoreMenu :entries="moreMenuEntries" />
            </template>
        </ListCard>
    </router-link>
</template>

<script setup lang="ts">
import { computed, toRefs } from "vue";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { MenuTextEntry } from "@/shared/components/menu/menu.model";
import sanitizeHtml from "sanitize-html";
import { useI18n } from "vue-i18n";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import MoreMenu from "@/shared/components/menu/more-menu.vue";
import PropertyView from "@/shared/components/views/property-view.vue";
import ShowIfHasAccess from "@/shared/access-control/components/show-if-has-access.vue";
import { useDocumentRoute } from "@/shared/router/document-route";
import ListCard from "@/shared/components/card/list-card.vue";
import {
    ContentMapResult,
    DocumentListCardProps,
    TopicResult,
} from "@/shared/documentation/document-list-card.types.ts";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access.ts";

const props = defineProps<DocumentListCardProps>();

const emit = defineEmits<{
    (e: "toggleCollectionOverlay", topicId: string, contentMapId: string, title: string): void;
    (e: "delete", data: { topicId?: string; contentMapId: string }): void;
}>();

const { document } = toRefs(props);
const { t } = useI18n();
const { getPathToDocumentationDetailPageForSearch } = useDocumentRoute();

const isTopic = computed(() => {
    return document.value.__typename === "Topic";
});

const onOpenOverlayRight = () => {
    emit(
        "toggleCollectionOverlay",
        (document.value as TopicResult).topicId,
        (document.value as ContentMapResult).contentMapId,
        document.value.title
    );
};

const onDelete = () => {
    emit("delete", {
        topicId: (document.value as TopicResult).topicId,
        contentMapId: (document.value as ContentMapResult).contentMapId,
    });
};

const highlight = computed(() => {
    const highlightText = document.value.highlightText?.[0];
    const highlightTitle = document.value.highlightTitle?.[0];
    const anyHighlightText = highlightText ?? highlightTitle;

    return anyHighlightText ? sanitizeHtml(anyHighlightText) : undefined;
});

const highlightsForPDF = computed(() => {
    if (isTopic.value && document.value.mimeType === "application/pdf") {
        const regex = /<em>(.*?)<\/em>/g;
        const textHighlights = document.value.highlightText;

        if (!textHighlights || textHighlights?.length === 0) return [];
        const highlights = new Set<string>();
        for (const textHighlight of textHighlights) {
            if (!textHighlights) continue;
            let match;
            while ((match = regex.exec(textHighlight!)) !== null) {
                highlights.add(match[1]);
            }
        }
        return [...highlights.values()];
    }
    return [];
});

const linkToDocumentationDetailPage = getPathToDocumentationDetailPageForSearch(
    (document.value as ContentMapResult).contentMapId,
    (document.value as TopicResult).topicId,
    highlightsForPDF.value,
    props.context
);

const { hasAccess: canWriteToCollection } = useHasAccess({
    resource: AccessResource.collections,
    action: AccessAction.write,
    externalContext: props.collectionContext,
});
const { hasAccess: canDeleteContent } = useHasAccess({
    resource: AccessResource.contents,
    action: AccessAction.delete,
    externalContext: props.collectionContext,
});

const moreMenuEntries = computed(() => {
    if (!props.moreMenu) return [];

    const entries: MenuTextEntry[] = [];
    if (canWriteToCollection.value) {
        entries.push({
            text: t("documentation.Add to another collection"),
            click: (e?: any) => {
                e?.event?.preventDefault();
                onOpenOverlayRight();
            },
        });
    }

    if (canDeleteContent.value) {
        entries.push({
            text: t("documentation.Delete"),
            click: onDelete,
        });
    }

    return entries;
});
</script>

<style lang="scss" scoped>
.properties {
    column-gap: $spacing-xxl;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}
</style>
