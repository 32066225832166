<template>
    <AddEntryToCollectionOverlay
        :visible="true"
        :collections="collections"
        :loading="isLoading"
        :selected="selected"
        @on-close="emits('onClose')"
        @on-save="onSave"
        @on-collection-selection-changed="onCollectionSelect"
        @on-collection-created="onCollectionCreated"
    >
        <template #entry>
            <div class="q-mt-md">
                <ListCard
                    :title="activity.title"
                    :subtitle="activity.id"
                    :locale-tag="activity.language"
                    card-type="small"
                    no-hover-shadow
                    container
                >
                    <template #image>
                        <div class="justify-center row">
                            <q-icon name="fa-thin fa-wrench icon-light-color" size="20px" />
                        </div>
                    </template>
                </ListCard>
            </div>
        </template>
    </AddEntryToCollectionOverlay>
</template>

<script setup lang="ts">
import {
    CollectionEntryNavigationContextInput,
    useAddActivitiesToCollectionMutation,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed, ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useQuasar } from "quasar";
import { useEditableCollections } from "@/abilities/collections/composables/editable-collections.ts";
import ListCard from "@/shared/components/card/list-card.vue";
import { updateCollectionCache } from "@/abilities/collections/graphql/collection-cache.ts";
import AddEntryToCollectionOverlay from "@/abilities/collections/components/overlay/add-entry-to-collection-overlay.vue";

export interface ActivityModelOverlay {
    id: string;
    title: string;
    language: string;
}

const { t } = useI18n();
const { notify } = useQuasar();
const selected = ref<Record<string, boolean>>({});
const saving = ref<boolean>(false);
const allSelected = ref<boolean>(false);

const props = defineProps<{
    activity: ActivityModelOverlay;
    assetId?: string;
    productId?: string;
}>();

const emits = defineEmits<{
    (e: "onSaveSucceeded"): void;
    (e: "onClose"): void;
}>();

const { activity, assetId, productId } = toRefs(props);

const { loading, collections, refetch } = useEditableCollections();
const { mutate: addActivitiesMutation } = useAddActivitiesToCollectionMutation({});

watch(
    () => [activity.value, productId.value],
    () => {
        selected.value = {};
        allSelected.value = false;
    }
);

const isLoading = computed(() => {
    return loading.value || saving.value;
});

const onCollectionSelect = (newSelected: Record<string, boolean>) => {
    selected.value = newSelected;
};

const addActivities = async (
    collectionId: string,
    activityIds: string[],
    context: undefined | CollectionEntryNavigationContextInput
) => {
    await addActivitiesMutation(
        { collectionId: collectionId, activityIds: activityIds, context },
        {
            update: updateCollectionCache(collectionId),
        }
    );
};

const onSave = async () => {
    const selectedCollections = Object.entries(selected.value);

    try {
        saving.value = true;
        let context: CollectionEntryNavigationContextInput | undefined = undefined;

        if (assetId.value || productId.value) {
            context = {};
            if (assetId.value) {
                context.assetId = assetId.value;
            }
            if (productId.value) {
                context.productId = productId.value;
            }
        }

        for (const [key] of selectedCollections) {
            await addActivities(key, [activity.value.id!], context);
        }
        allSelected.value = false;
        notify({
            message: t("collections.Added to collection successfully"),
            type: "positive",
        });
        emits("onSaveSucceeded");
    } catch (e: any) {
        notify({ message: e.message ? e.message : e });
        console.error(e);
    } finally {
        selected.value = {};
        saving.value = false;
    }
};

const onCollectionCreated = async () => {
    await refetch();
};
</script>

<style lang="scss" scoped>
.article-id {
    font-size: 12px;
    color: $light-text-color;
}

:deep(.card-small-mode:hover) {
    background: $white;
}
</style>
