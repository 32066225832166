import { Theme, useThemeQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { computed, ref, watch } from "vue";
import { InfoTwinTheme } from "./theme.types";
import {
    convertToThemeColors,
    createThemeObject,
    getDefaultThemeColors,
    isThemeObject,
} from "@/shell/helpers/theme.helpers";
import { useTenant } from "@/shared/composables/tenant";

const themeDefinitions = ref<InfoTwinTheme>();

const colors = computed(() => {
    if (themeDefinitions.value?.colors) return themeDefinitions.value?.colors;

    return getDefaultThemeColors();
});

export const useTheme = () => {
    const { tenant } = useTenant();
    const { result } = useThemeQuery(
        computed(() => {
            return {
                acceptedLanguages: getDataQueryLanguages(),
            };
        }),
        computed(() => ({ enabled: !!tenant.value, clientId: "public" }))
    );

    const themeLoading = ref<boolean>(true);

    watch(result, () => {
        if (!result.value) return;

        const themeTitle = result.value.theme?.teasers?.title
            ? result.value.theme?.teasers?.title
            : tenant.value?.displayName!;

        themeDefinitions.value = createThemeObject(result.value, themeTitle);

        themeLoading.value = false;

        if (!window.matchMedia("(display-mode: standalone)").matches) document.title = themeTitle;
    });

    /**
     * Changes the InfoTwin theme temporarily for the Branding preview
     * @param theme Theme from message event.
     */
    function changeTheme(theme: Theme) {
        if (!themeDefinitions.value) return;

        const themeDefinitionCopy = { ...themeDefinitions.value };

        themeDefinitionCopy.logoURL = theme.logo?.blobId
            ? `${window.location.origin}/downloads/blobs/${theme.logo?.blobId}`
            : "";
        themeDefinitionCopy.logoPath = theme.logo?.path ? theme?.logo?.path : "";
        themeDefinitionCopy.showTitle = theme.showTitle ?? true;
        themeDefinitionCopy.logoPath = theme.logo?.path ?? "";
        themeDefinitionCopy.colors = convertToThemeColors(theme.theming?.colors);
        themeDefinitionCopy.title = theme.teasers?.title ? theme.teasers.title : tenant.value?.displayName!;
        themeDefinitions.value = themeDefinitionCopy;
    }

    /**
     * Receives the message from the event listener, only activates if it comes from the same origin
     * and filters the message, to check if its a ThemeObject and not something else.
     * @param event Event data from the message event listener.
     * @returns
     */
    function receiveMessage(event: { data: any; origin: string }) {
        if (location.hostname !== "localhost" && event.origin !== window.location.origin) return;

        if (isThemeObject(event.data)) {
            changeTheme(event.data);
        }
    }

    return {
        themeDefinitions,
        receiveMessage,
        colors,
        themeLoading,
    };
};
