import { computed, Ref } from "vue";
import { useLoadConfigProductAndAsset } from "@/abilities/product-and-asset/settings/load-product-and-asset-settings";
import { getI18n } from "@/shared/i18n/i18n";
import { AssetNameTypes, assetTextKeys } from "@/abilities/product-and-asset/helpers/product-asset-naming";

export function useAssetNames(subType: AssetNameTypes, params?: Ref<any>) {
    const { loading, configProductAndAsset } = useLoadConfigProductAndAsset();
    const i18n = getI18n();

    return computed(() => {
        if (!loading.value && configProductAndAsset.value) {
            //@ts-ignore
            return i18n.global.t(
                "product-and-asset." + assetTextKeys[configProductAndAsset.value.assetName][subType],
                //@ts-ignore
                params?.value
            );
        }
        return "";
    });
}
