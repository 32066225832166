<template>
    <ExpansionPanel
        icon="fa-regular fa-gear icon-light-color"
        :label="$t('mechanic.Identify Component')"
        v-if="isWidgetVisible"
    >
        <q-card class="container-shadow row q-pa-md">
            <div v-for="item in identifyPartsItems" :key="item.titleKey" class="col">
                <IdentifyPartBoxItem class="identify-part-item" :identify-parts-item="item" />
            </div>
        </q-card>
    </ExpansionPanel>
</template>

<script setup lang="ts">
import { ref, watch, watchEffect } from "vue";
import { getIdentifyPartsItems, IDENTIFY_PART } from "./identify-part.model";
import { WidgetContext } from "@/shared/environment/widgets/widget-context";
import { useAvailablePartsListQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import IdentifyPartBoxItem from "@/abilities/mechanic/pages/components/identify-part-item.vue";
import ExpansionPanel from "@/shared/components/expansion/expansion-panel.vue";
//@ts-ignore
import { getStore } from "@/shared/store/store";
import { encodeParamArrayToString } from "@/shared/environment/ability-route-helper";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { useAbilityRoute } from "@/shared/environment/ability-route";

const store = getStore();

const { getPathToChild } = useAbilityRoute();

watch(
    () => store.state.notification.mechanicDeleted,
    (value) => {
        if (value > 0) {
            refetch();
        }
    }
);

const props = defineProps<{
    widgetContext: WidgetContext;
}>();

function identifyPartButtonAction() {
    // Einstieg in der ersten Knoten der Mechanic. Der erste Knoten ist immer ''
    return getPathToChild(CommonRouteAliases.mechanic, {
        [CommonRouteAliases.mechanic]: encodeParamArrayToString([""]),
    });
}

function getProductId(): string | undefined {
    if (props.widgetContext.__type === "product") {
        return props.widgetContext.productId;
    }
    if (props.widgetContext.__type === "asset") {
        return props.widgetContext.product;
    }
    return undefined;
}

function getAssetId(): string | undefined {
    if (props.widgetContext.__type === "asset") {
        return props.widgetContext.asset;
    }
    return undefined;
}

const identifyPartsItems = getIdentifyPartsItems().map((item) => {
    item.productId = getProductId() ?? "";
    item.assetId = getAssetId();

    if (item.identifyPartsItemKey === IDENTIFY_PART) {
        item.getButtonRoute = identifyPartButtonAction;
    }
    return { ...item };
});

const filterVariables = {
    filter: {
        andGroup: [
            {
                equals: {
                    parentAssemblyId: "",
                },
            },
        ],
    },
    assetId: getAssetId(),
    productId: getProductId(),
    first: 1,
};

const { loading, result, refetch } = useAvailablePartsListQuery(filterVariables, { fetchPolicy: "no-cache" });

const isWidgetVisible = ref(false);

watchEffect(() => {
    isWidgetVisible.value =
        result.value?.mechanics !== undefined &&
        !loading.value &&
        result.value.mechanics.mechanics &&
        result.value.mechanics.mechanics.length > 0;
});
</script>

<style lang="scss" scoped>
.identify-part-container {
    display: grid;
}

.identify-part-horizontally {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: $spacing-xxl;
    padding: 0;

    > div {
        max-width: 50%;
    }
}

.identify-part-vertically {
    grid-template-columns: repeat(1, 1fr);

    .identify-part-item {
        border-bottom: 1px solid $default-border-color;
        padding: $spacing-l 0;
    }

    .identify-part-item:last-child {
        border-bottom: none;
    }
}
</style>
