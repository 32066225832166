<template>
    <div v-if="routeToArticle">
        <AdvancedRouterLink class="router-link" :to="routeToArticle" :disabled="hasMultiplePartsInProductContext">
            <template #content>
                <div class="item-content">
                    <div
                        class="item-content-container"
                        data-qs="assembly-navigation-hide-overlay-button"
                        @click="articleWithPathsClicked"
                    >
                        <div class="skeleton-square preview-image-container" v-if="loading" />

                        <div v-else class="preview-image-container">
                            <PreviewImage
                                :image-src="articleNode.previewImage?.url"
                                fallback-icon="fa-regular fa-gear fa-3x icon-light-color"
                            />
                        </div>
                        <div class="article-info">
                            <div v-if="loading || !articleNode" class="skeleton-line percent80" />
                            <div v-else class="article-content qit-text-ellipsis-2-lines">
                                <span v-if="!hasMultiplePartsInProductContext && articleTitle" class="article-title">
                                    {{ articleTitle }}
                                </span>
                                <span class="property-description">
                                    {{ $t("mechanic.Article ID") }}
                                </span>
                                <span>{{ articleNode.articleNumber }}</span>
                                <span v-if="hasMultiplePartsInProductContext" class="multiple-articles"
                                    ><i>{{
                                        $t("mechanic.There are several installation locations for this article number")
                                    }}</i></span
                                >
                            </div>
                        </div>
                        <div class="navigation-button" v-if="hasMultiplePartsInProductContext">
                            <QitButton
                                color="transparent"
                                data-qs="jump-to-article-paths-button"
                                icon="fa-regular fa-chevron-right medium-text-color"
                                class="fit"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </AdvancedRouterLink>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useMechanicLocation } from "../../helpers/mechanic-location-helper";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import {
    ArticleEdge,
    Maybe,
    MechanicEdge,
    MechanicPath,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import AdvancedRouterLink from "@/shared/components/router-link/advanced-router-link.vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useMechanicArticleRoute } from "@/shared/router/mechanic-article-route.ts";
import PreviewImage from "@/shared/components/image-item/preview-image.vue";

const props = withDefaults(
    defineProps<{
        article: ArticleEdge | MechanicEdge;
        product?: string;
        asset?: string;
    }>(),
    {}
);

const emit = defineEmits<{
    (e: "secondLevelClick", mechanicPaths: MechanicPath[]): void;
}>();

const { getPathToChildWithoutParents } = useAbilityRoute();
const { getPathToMechanicArticle } = useMechanicArticleRoute();

const { paths, mechanics, loading } = useMechanicLocation(
    computed(() => {
        return {
            first: 2,
            acceptedLanguages: getDataQueryLanguages(),
            articleId: props.article.node.articleNumber ?? "",
            product: props.product ?? "",
            asset: props.asset ?? "",
        };
    })
);

const articleTitle = ref<Maybe<string> | undefined | string>("");

const mechanicId = computed(() => {
    if (mechanics.value && mechanics.value.mechanics.length > 0) {
        return (mechanics.value.mechanics[0] as MechanicEdge).node.id;
    }
    return undefined;
});

const mechanicTitle = computed(() => {
    if (mechanics.value && mechanics.value.mechanics.length > 0) {
        return (mechanics.value.mechanics[0] as MechanicEdge).node.localizations?.title;
    }
    return undefined;
});

const articleNode = computed(() => {
    return props.article.node;
});

watch([articleNode, mechanicTitle], () => {
    if (props.product && mechanicTitle.value) {
        articleTitle.value = mechanicTitle.value;
    } else if (articleNode.value) {
        articleTitle.value = articleNode.value.localizations?.title;
    }
});

const hasMultiplePartsInProductContext = computed(() => {
    return !!(props.product && paths.value.length > 1);
});

const routeToArticle = computed(() => {
    if (props.product) {
        return getPathToMechanicArticle(
            props.product ?? "",
            props.asset,
            mechanicId.value ?? props.article.node.id ?? "" ?? ""
        );
    } else {
        return getPathToArticle();
    }
});

function getPathToArticle() {
    return getPathToChildWithoutParents(CommonRouteAliases.commonArticle, {
        [CommonRouteAliases.commonArticle]: props.article.node.articleNumber ?? "",
    });
}

function articleWithPathsClicked() {
    if (hasMultiplePartsInProductContext.value) {
        emit("secondLevelClick", paths.value);
    }
}
</script>

<style lang="scss" scoped>
.item-content-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    cursor: pointer;

    &:hover & {
        background-color: var(--q-highlight-hover);
    }
}

.navigation-button {
    flex-shrink: 0;
    flex-grow: 0;
}

.item-content {
    display: flex;
    border: 1px solid $default-border-color;
    border-radius: $default-border-radius;
    font-weight: $semi-bold;
    width: 100%;
}

.article-info {
    flex-grow: 3;
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
    flex-shrink: 1;
    display: flex;
    overflow: hidden;
    .article-content {
        display: flex;
        flex-direction: column;
        .article-title {
            font-weight: $bold;
            margin-bottom: $spacing-s;
        }
        .property-description {
            color: $light-text-color;
            font-size: $smaller-font-size;
        }
        .multiple-articles {
            margin-top: $spacing-s;
            font-size: $smaller-font-size;
        }
    }
}

.preview-image-container {
    border: 1px solid $default-border-color;
    border-radius: $default-border-radius;
    margin: $spacing-m;
    width: $preview-image-width;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: $grey-95;
    height: $preview-image-height;

    &:deep(img) {
        border-radius: $default-border-radius;
    }
    &:deep(.preview-image) {
        min-height: 71px;
        height: 71px;
    }
}

.default-preview-image-container {
    height: 175px;
}
</style>
