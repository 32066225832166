<template>
    <div class="q-px-md q-pb-md container-in-search-suggestions">
        <div class="suggestionsTitles text-uppercase">
            <b class="black-text">{{ searchInputValue }}</b> {{ $t("core.Search in") }}
        </div>
        <!-- Buttons for searching in different contexts -->
        <HeaderSearchInButtons @on-context-selection="onContextSearchHandler" :items="searchTabs ?? []" />
    </div>
</template>

<script lang="ts" setup>
import HeaderSearchInButtons from "@/shell/pages/header/search/search-in-context.vue";
import { defineProps, defineEmits } from "vue";
import { SearchTab } from "@/shared/environment/ability.types";

defineProps<{
    searchInputValue: String;
    searchTabs: SearchTab[];
}>();

const emit = defineEmits(["onContextSelection"]);

const onContextSearchHandler = (searchInCategory: string) => {
    emit("onContextSelection", searchInCategory);
};
</script>
