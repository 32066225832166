<template>
    <Dialog :visible="visible" data-qs="create-qr-code-dialog">
        <template #header>
            <div class="row items-center">
                <q-btn
                    class="back-btn"
                    color="secondary"
                    size="sm"
                    icon="fa-regular fa-arrow-left icon-color"
                    @click="onClose"
                />
                <div class="q-pl-md bold text-h6">
                    {{ $t("core.Connect device") }}
                </div>
            </div>
        </template>
        <template #content>
            <div class="q-pb-lg">
                {{
                    $t(
                        "core.Link the InfoTwin Companion App to your profile to be able to download content from InfoTwin to your mobile device"
                    )
                }}
            </div>
            <div class="semi-bold">1. {{ $t("core.Open app") }}</div>
            <p>{{ $t("core.The InfoTwin Companion App is provided by your system administrator") }}</p>
            <div class="semi-bold">2. {{ $t("core.Scan QR-Code") }}</div>
            <p>
                {{
                    $t(
                        "core.Generate a customised QR code here and scan it in the InfoTwin Companion App to link the device"
                    )
                }}
            </p>
            <div>
                <q-btn
                    data-qs="create-qrcode-btn"
                    class="full-width"
                    color="primary"
                    :label="label"
                    :icon="appKey ? 'fa-regular fa-arrows-rotate' : 'fa-regular fa-qrcode'"
                    :disable="isCreating"
                    @click="createCode"
                />
            </div>
            <div v-if="appKey" class="column justify-center items-center">
                <div class="q-pt-md">
                    <vue-qrcode :value="appKey.apiPass" :options="{ width: 250 }" />
                </div>
                <div>
                    {{
                        `${$t("core.QR-Code valid until")} ${new Intl.DateTimeFormat(locale).format(
                            new Date(appKey.valid)
                        )}`
                    }}
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from "vue";
import { useCreateAppKeyMutation } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { getUiLanguage } from "@/shared/services/providers/language-provider.ts";
import { useI18n } from "vue-i18n";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Dialog from "@/shared/components/dialog/dialog.vue";
import { useQuasar } from "quasar";

const props = defineProps<{ visible: boolean }>();

const emit = defineEmits<{
    onClose: [];
}>();

const { visible } = toRefs(props);
const isCreating = ref<boolean>(false);
const appKey = ref<undefined | { apiPass: string; valid: string }>();

const { notify } = useQuasar();
const { t } = useI18n();
const { mutate } = useCreateAppKeyMutation();

const onClose = () => {
    emit("onClose");
};

const locale = computed(() => {
    return getUiLanguage();
});

const createCode = async () => {
    isCreating.value = true;
    appKey.value = undefined;

    try {
        const result: any = await mutate();
        if (result?.data?.createAppKey)
            appKey.value = {
                apiPass: result.data.createAppKey.apiPass,
                valid: result.data.createAppKey.key.validUntil,
            };
    } catch (e: any) {
        console.error(e);
        notify({ message: typeof e === "string" ? e : e.message, type: "negative" });
    } finally {
        isCreating.value = false;
    }
};

const label = computed(() => {
    if (isCreating.value) return t("core.QR-Code is generated");
    return appKey.value ? t("core.Renew QR-Code") : t("core.Connect device");
});
</script>

<style lang="scss" scoped>
.back-btn {
    width: 36px;
    height: 36px;
}
</style>
