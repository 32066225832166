import { useRouter } from "vue-router";
import { createNavigationEntries, NavigationEntry } from "@/shell/helpers/app-navigation";
import { computed, ComputedRef, Ref, ref } from "vue";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { TextObject } from "@/shared/i18n/translation-types";
import { useAbilityStore } from "@/shared/store/ability.store";
import { storeToRefs } from "pinia";
import { useDocQueryParams } from "@/shared/composables/doc-query-params.ts";

export interface NavItem {
    visible: boolean;
    selected: boolean;
    index: number;
    icon: string;
    title: TextObject;
    path: string;
    alias?: string | undefined;
    externalLink?: string | undefined;
}

export function useNavItems() {
    const router = useRouter();
    const abilityStore = useAbilityStore();
    const { abilities } = storeToRefs(abilityStore);
    const { queryParams } = useDocQueryParams();
    const accessGrantedForItem = (item: NavigationEntry): Ref<boolean> => {
        if (item.access) {
            const { hasAccess } = useHasAccess({
                action: item.access.accessAction,
                resource: item.access.accessResource,
                featureID: item.access.accessFeature,
                ignoreConditions: true,
            });
            return hasAccess;
        }
        return ref(true);
    };

    const abilityOfItemIsActive = (item: NavigationEntry): Ref<boolean> => {
        return computed(() => {
            return !!abilities?.value?.find((ability) => ability.alias === item.abilityAlias);
        });
    };

    const navigationEntries = createNavigationEntries();

    // im computed der navElements kann kein useQuery aufgerufen werden, da sonst der Komponentenbezug verloren geht.
    // Um auch die Sichtbarkeit durch die Berechtigungen zu bestimmen, werden die Ergebnisse der Rechte-Prüfung zu einer Route
    // als Workaround in einer Map zwischengespeichert
    // Für die ausgeblendeten Abilities gilt das gleiche, deshalb werden die erst hier ausgefiltert
    const itemIsVisibleMap = new Map<number, { hasAccess: Ref<boolean>; abilityIsActive: Ref<boolean> }>();
    navigationEntries.forEach((item) => {
        const hasAccess = accessGrantedForItem(item);
        const abilityIsActive = abilityOfItemIsActive(item);
        itemIsVisibleMap.set(item.index, {
            hasAccess: hasAccess,
            abilityIsActive: abilityIsActive,
        });
    });

    const navElements: ComputedRef<NavItem[]> = computed(() => {
        return navigationEntries.map((item) => {
            if (item.path && !/^\//.test(item.path)) {
                item.path = `/${item.path}`;
            }
            if (item.queryParams && Object.keys(queryParams.value).length > 0) {
                const searchParams = new URLSearchParams(queryParams.value);
                item.path = `${item.pathWithoutParam}?${searchParams.toString()}`;
            }

            function getSelected() {
                if (item.alias) {
                    return router.currentRoute.value?.path?.includes(item.alias);
                } else {
                    return false;
                }
            }

            function getVisible() {
                const visible = item.isVisible ? item.isVisible() : true;
                const result = itemIsVisibleMap.get(item.index);
                if (result) {
                    return visible && result.hasAccess.value && result.abilityIsActive.value;
                } else {
                    return visible;
                }
            }

            return {
                ...item,
                visible: getVisible(),
                selected: getSelected(),
            };
        });
    });

    return { navElements };
}
