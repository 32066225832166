import { useTenantQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { computed } from "vue";
import { TenantAuthConfig } from "@/shared/services/graphql/generated/public-graph-types";

export interface Tenant {
    defaultLanguage: string;
    availableLanguages: string[];
    authConfig: TenantAuthConfig;
    displayName: string;
    landingPage?: string;
    docsRouteParams?: Record<string, string>;
}

export const useTenant = () => {
    const { result, error, loading } = useTenantQuery({ clientId: "public" });

    const tenant = computed<Tenant | undefined>(() => {
        if (!result.value) return undefined;

        return {
            defaultLanguage: result.value.tenant.languages?.default!,
            availableLanguages: (result.value.tenant.languages?.available as string[]) || [],
            authConfig: result.value.tenant.auth as TenantAuthConfig,
            displayName: result.value.tenant.displayName,
            landingPage: result.value?.tenant.landingPage?.startsWith("/")
                ? result.value?.tenant.landingPage
                : undefined,

            docsRouteParams: result.value?.tenant.tenantSettings?.docsRouteParams,
        };
    });
    return { tenant, error, loading };
};
