<template>
    <Card
        :title="$t('product-and-asset.My last {assetsName}', { assetsName: assetName })"
        title-icon="fa-regular fa-tag light-border-color"
        :show-footer="false"
        class="small-content-container"
    >
        <template v-if="loading">
            <LastAssetSkeletonTile />
        </template>
        <template v-else-if="isEmpty(assets)">
            <NoLastElement :is-product="false" />
        </template>
        <template v-else>
            <div class="asset-detail-tile" v-for="(asset, index) in assetsByIds" :key="asset.id">
                <router-link :to="getPathToAsset(asset)">
                    <LastProductAssetListCard
                        :advanced-product-or-asset="assetWithId(asset.id)"
                        :test-id="`last-assets-${index}`"
                        fallback-icon="fa-regular fa-tag fa-3x icon-light-color"
                    />
                </router-link>
            </div>
        </template>
        <template #close>
            <router-link :to="getPathToProductSelection(CommonSearchTabAliases.assets)" class="router-link">{{
                $t("product-and-asset.Show all {assetsName}", { assetsName: assetName })
            }}</router-link>
        </template>
    </Card>
</template>

<script lang="ts" setup>
import { computed, ComputedRef } from "vue";
import Card from "@/shared/components/card/card.vue";
import { CommonRouteAliases, CommonSearchTabAliases } from "@/shared/environment/common-route-aliases";
import { WidgetContext } from "@/shared/environment/widgets/widget-context";
import { getPathToProductSelection } from "../../helpers/product-selection-routing";
import LastProductAssetListCard from "./last-product-asset-list-card.vue";
import LastAssetSkeletonTile from "./last-product-asset-skeleton-tile.vue";
import { isEmpty } from "lodash";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { RouterLink } from "vue-router";
import { useAssetsResultWithDisplayConfig } from "./last-assets.widget.model";
import { AdvAsset } from "../../data-objects/advanced-asset";
import { useProductsAssetsHistory } from "@/abilities/product-and-asset/store/products-assets-history";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useAssetNames } from "@/abilities/product-and-asset/composables/asset-names";
import NoLastElement from "./no-last-element.vue";

defineProps<{
    widgetContext: WidgetContext;
}>();

const assetName = useAssetNames("pluralLower");
const { lastAssetIDs, loading: loadingLastAssetIds } = useProductsAssetsHistory();
const { getPathToChildrenWithoutParents } = useAbilityRoute();

function getPathToAsset(asset: AdvAsset) {
    return getPathToChildrenWithoutParents([
        {
            alias: CommonRouteAliases.product,
            namedParams: { [CommonRouteAliases.product]: asset.productId ?? "" },
        },
        {
            alias: CommonRouteAliases.asset,
            namedParams: { [CommonRouteAliases.asset]: asset.assetId ?? "" },
        },
    ]);
}

const assetIDs: ComputedRef<string[]> = computed(() => {
    return lastAssetIDs.value;
});

const { loading: loadingAssetDisplayConfig, assets } = useAssetsResultWithDisplayConfig(
    computed(() => {
        return {
            assetIds: assetIDs.value,
            acceptedLanguages: getDataQueryLanguages(),
        };
    })
);

const loading = computed(() => {
    return loadingAssetDisplayConfig.value || loadingLastAssetIds.value;
});

// Graphql does not return the assets in the order that was requested,
// therefore we sort the graphql assets result by the productIds-Array
const assetsByIds = computed(() => {
    if (assets.value) {
        const assetsByIdsSort = assets.value;
        assetsByIdsSort.sort((asset1, asset2) => {
            return assetIDs.value.indexOf(asset1.id) - assetIDs.value.indexOf(asset2.id);
        });
        return assetsByIdsSort;
    }
    return [];
});

function assetWithId(id: string) {
    return assets.value.find((asset) => asset.id === id);
}
</script>

<style lang="scss" scoped>
.router-link {
    font-weight: $semi-bold;
    color: var(--q-link);
}
</style>
