<template>
    <AdvancedRouterLink :to="item.routeLocation">
        <template #content>
            <ListCard
                :title="item.title"
                :highlight="item.highlight"
                :image-separator="!!item.previewImageUrl"
                :index="index"
            >
                <template #image>
                    <PreviewImage :image-src="item.previewImageUrl" :fallback-icon="getEntryImageClasses(item)" />
                </template>
                <template #subtitle>
                    <!-- @vue-ignore -->
                    <div v-if="item.rootContentMapTitle" class="col subtitle light-text-color qit-text-ellipsis">
                        <!-- @vue-ignore -->
                        {{ $t("documentation.in {path}", { path: item.rootContentMapTitle }) }}
                    </div>
                    <div v-if="item.subheading" class="col subtitle light-text-color qit-text-ellipsis">
                        {{ item.subheading }}
                    </div>
                </template>
                <template #content>
                    <div v-if="item.properties" class="properties row">
                        <PropertyView :items="item.properties" :max-property-width="180" />
                    </div>
                </template>
                <template #buttons>
                    <div class="row-auto q-ma-sm">
                        <MoreMenu v-if="moreMenuEntries" :entries="moreMenuEntries" />
                    </div>
                </template>
            </ListCard>
        </template>
    </AdvancedRouterLink>
</template>

<script setup lang="ts">
import { MenuTextEntry } from "@/shared/components/menu/menu.model";
import MoreMenu from "@/shared/components/menu/more-menu.vue";
import PropertyView from "@/shared/components/views/property-view.vue";
import AdvancedRouterLink from "@/shared/components/router-link/advanced-router-link.vue";
import PreviewImage from "@/shared/components/image-item/preview-image.vue";
import ListCard from "@/shared/components/card/list-card.vue";
import { CollectionEntryTypes } from "@/abilities/collections/components/collection-entry.types.ts";

defineProps<{
    item: CollectionEntryTypes;
    moreMenuEntries?: MenuTextEntry[];
    index?: number;
}>();

const emit = defineEmits<{
    openOverlayRight: [];
    delete: [];
}>();

const getEntryImageClasses = (item: CollectionEntryTypes) => {
    if (item.__typename === "Article" || item.__typename === "MechanicArticle") {
        return "fa-light fa-gear icon-color fa-3x";
    } else if (item.__typename === "Documentation") {
        if (item.isTopic) return "fa-file-lines fa-light icon-light-color fa-3x";
        return "fa-book fa-light icon-color fa-3x";
    } else if (item.__typename === "Activity") {
        return "fa-light fa-wrench icon-color fa-3x";
    }

    return "";
};
</script>

<style lang="scss" scoped>
.properties {
    gap: $spacing-xxl;
}
</style>
