<template>
    <Activities :text="text" :product="product" :asset="asset" hide-title />
</template>

<script setup lang="ts">
import { computed } from "vue";
import Activities from "@/abilities/activities/pages/activities.vue";

const props = defineProps<{
    search: Record<string, string>;
}>();

const text = computed(() => {
    return props.search.text;
});

const product = computed(() => {
    return props.search.product;
});

const asset = computed(() => {
    return props.search.asset;
});
</script>
